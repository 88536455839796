.active_label {
  font-size: 18px;
  font-weight: 600;
}
.deavtive_label {
  font-size: 18px;
  font-weight: 500;
  opacity: 0.6;
}
.jodit_editor-dealerpull {
  .jodit-container {
    min-height: 200px !important;
    .jodit-workplace {
      min-height: 200px !important;
    }
  }
}
.dealerpull_vehicle_detaile {
  h5 {
    color: black;
  }
}
.vin_vehicle-input {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.vin_vehicle-button {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
.add_vehicle-jodieditor {
  .jodit-container {
    min-height: 450px !important;
    .jodit-workplace {
      height: 450px !important;
    }
  }
}
.form_multiselect-style {
  .rmsc .dropdown-container {
    border-radius: 30px;
    border: 0px solid transparent !important;
  }
}
// .popover {
//   max-width: 320px !important;
// }
