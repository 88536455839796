.vehicle_info-card {
  background-color: #ffffff;
  background-size: 0% 100%;
  border-left: 12px solid #8e9eab;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  transition: background-color 1s;
}
.avatarHello {
  opacity: 0;
}
.transition-3 {
  opacity: 0;
}
.home_button_onlinechat {
  cursor: pointer;
  box-shadow: 0px 0px 16px 6px rgba(0, 0, 0, 0.33);
  border-radius: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 84px;
  width: "84px" !important;
  height: "84px" !important;
  background-image: url("../../image/Group\ 2705.png");
  &:hover {
    .avatarHello {
      // Position
      opacity: 1;

      position: absolute;
      // position: 'absolute',
      left: calc(-100% - 44px - 28px);
      top: calc(50% - 24px);
      // Layering
      z-index: 10000;
      box-shadow: 0px 0px 16px 6px rgba(0, 0, 0, 0.33);
      // Border
      padding: 12px 12px 12px 16px;
      border-radius: 24px;
      // Color
      background-color: "#f9e215";
      color: "black";
    }
    .transition-3 {
      opacity: 1;
      transition: all 0.33s ease;
      -webkit-transition: all 0.33s ease;
      -moz-transition: all 0.33s ease;
    }
  }
}
.vehicle_info-card:hover {
  color: #ffffff;
  background-image: linear-gradient(to right, #8e9eab 0%, #8e9eab 100%);
  background-repeat: no-repeat;
  background-size: 100% 200%;
  transition: background-size 1s, background-color 1s;
}
.vehicle_info-card:hover .vehicle_icons {
  color: #ffffff;
  transition: color 1s;
}
.lead_info-card {
  background-color: #ffffff;
  background-size: 0% 100%;
  border-left: 12px solid $purple-color;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  transition: background-color 1s;
}
.lead_info-card:hover {
  color: #ffffff;
  background-image: linear-gradient(
    to right,
    $purple-color 0%,
    $purple-color 100%
  );
  background-repeat: no-repeat;
  background-size: 100% 200%;
  transition: background-size 1s, background-color 1s;
}
.lead_info-card:hover .lead_icons {
  color: #ffffff;
  transition: color 1s;
}
.customer_info-card {
  background-color: #ffffff;
  background-size: 0% 100%;
  border-left: 12px solid $green-color;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  transition: background-color 1s;
}
.customer_info-card:hover {
  color: #ffffff;
  background-image: linear-gradient(
    to right,
    $green-color 0%,
    $green-color 100%
  );
  background-repeat: no-repeat;
  background-size: 100% 200%;
  transition: background-size 1s, background-color 1s;
}
.customer_info-card:hover .seller_customer_icons {
  color: #ffffff;
  transition: color 1s;
}
.task_info-card {
  background-color: #ffffff;
  background-size: 0% 100%;
  border-left: 12px solid #f38abc;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  transition: background-color 1s;
}
.task_info-card:hover {
  color: #ffffff;
  background-image: linear-gradient(to right, #f38abc 0%, #f38abc 100%);
  background-repeat: no-repeat;
  background-size: 100% 200%;
  transition: background-size 1s, background-color 1s;
}
.task_info-card:hover .task_icons {
  color: #ffffff;
  transition: color 1s;
}
.lead_icons {
  font-size: 1.4rem;
  color: $purple-color;
  transition: color 0.5s;
}
.vehicle_icons {
  font-size: 1.4rem;
  color: #8e9eab;
  transition: color 0.5s;
}
.seller_customer_icons {
  font-size: 1.4rem;
  color: $green-color;
  transition: color 0.5s;
}
.task_icons {
  font-size: 1.4rem;
  color: #f38abc;
  transition: color 0.5s;
}
.users_avatar-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.vehicle_management-image {
  width: 60px;
  height: 60px;
  // border-radius: 50%;
  background-color: #ffffff;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.vehicle_avatar-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #ffffff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.dealeship_logo-profile {
  width: 200px;
  height: 80px;
  border-radius: 50%;
  background-color: #ffffff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.profile_avatar-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #ffffff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.profile_avatar-image_customer_page {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-color: #ffffff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.customer_profile_border {
  border: 1px dashed #000;
  border-top: none;
}
.user_border-style {
  border-bottom: 2px solid #8e9eab;
  transition: border-radius 1s;
}
.user_border-style:hover {
  cursor: pointer;
  border-radius: 25px;
  transition: border-radius 1s;
  -webkit-box-shadow: -2px 3px 18px 4px rgba(0, 0, 0, 0.16) !important;
  box-shadow: -2px 3px 18px 4px rgba(0, 0, 0, 0.16) !important;
}

/* .vehicle_container-style {
    position: relative;
    overflow: hidden;
    overflow-x: scroll;
  } */
// .border_for_splite {
//   border-left: 3px solid $gray-color;
// }
.condition_points {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.color_changer-vehicle-condition {
  color: $gray-color;
}
strong.color_changer-vehicle-condition {
  font-size: 1.2rem;
}
.color_changer-lead_customer {
  color: $gray-color;
  font-size: 0.8rem !important;
}
.container-vehicle-condition:hover {
  background-color: rgba($color: $gray-color, $alpha: 0.05) !important;
  -webkit-box-shadow: 0px 0px 16px 3px rgba($color: $gray-color, $alpha: 0.2);
  box-shadow: 0px 0px 16px 3px rgba($color: $gray-color, $alpha: 0.2);
  border-radius: 10px;
  cursor: pointer;
}
.dashboard_divider-style-hidden {
  position: fixed;
  top: 10%;
  right: 10px;
  transform: translateX(+95%);
  transition: transform 0.5s ease;
}
.dashboard_divider-style-show {
  position: fixed;
  top: 10%;
  right: 10px;
  transform: translateX(0);
  transition: transform 0.5s ease;
}
.dashboard_divider-openner {
  position: absolute;
  top: 10px;
  left: -15px;
  background-color: $green-color;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.openner_icon-style {
  color: #ffffff;
  font-size: 0.7rem;
}
.forward_openner-icon {
  transform: rotate(180deg);
  transition: transform 0.5s ease;
}
.backward_openner-icon {
  transform: rotate(0deg);
  transition: transform 0.5s ease;
}
.divider_icons {
  font-size: 1.7rem;
  color: $gray-color;
  margin-right: 30px;
}
.option_tab_selected_class-name {
  color: $green-color !important;
  list-style: none;
  cursor: pointer;
  // background-color: #e7ebee !important;
  // -webkit-box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.11) !important;
  // box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.11) !important;
  .icon_tab {
    -webkit-box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.11) !important;
    box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.11) !important;
  }
}

.dashborad_tab_selected_class-name {
  color: $green-color !important;
  list-style: none;
  cursor: pointer;
}
.dashborad_tab_selected_class-name:focus {
  color: $green-color !important;
}
.option_tab_class-name {
  color: $gray-color;
  font-size: 1.7rem;
  img {
    color: $green-color !important;
  }
}
.option_tab_class-name:hover {
  color: $green-color !important;
  cursor: pointer;
  img {
    color: $green-color !important;
  }
  // background-color: #e7ebee;
}
.dashborad_tab_class-name {
  color: $gray-color;
  font-size: 1.7rem;
}
.dashborad_tab_class-name:hover {
  color: $green-color !important;
  cursor: pointer;
}
.modalpopup {
  font-size: 17px;
}
.popup-overlay {
  background-color: rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(1px) !important;
}
.modalpopup > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  background-color: $green-color;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modalpopup > .content {
  width: 100%;
  padding: 10px 5px;
}
.modalpopup > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modalpopup > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
.popup-content {
  border-radius: 10px !important;
  padding: 0 !important;
}
.popup-content {
  @media screen and (max-width: 47.9375rem) {
    // @content;
    top: 0%;
  }
  top: -17%;
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}
@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    top: 300px;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  5% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    top: -300px;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.user_name {
  color: #1978a5 !important;
  text-decoration: none !important;
}
.style-tab {
  .nav-tabs,
  .nav-link {
    color: #000;

    &:hover {
      color: #1978a5;
    }
  }
  .nav-link.active {
    color: #1978a5;
  }
}
.style_nav_link {
  .nav-link {
    display: flex;
    align-items: center;
    padding: 6px;
    margin: 0;
  }
  // .nav-link {
  //   font-size: 20px;
  //   font-family: open-sans, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

  //   /* width: 100%; */
  //   color: #f6f7fb !important;

  //   &:hover {
  //     padding: 6px;
  //     /* font-size: 30px; */
  //     background-color: #f6f7fb;
  //     width: 100%;
  //     color: $green-color !important;
  //     border-bottom-left-radius: 25px;
  //     border-top-left-radius: 25px;
  //     // margin-right: 30px;
  //     .sidebar_svg_icons {
  //       fill: $green-color;
  //     }
  //   }
  // }
  // .nav-link.active {
  //   padding: 6px;
  //   /* font-size: 30px; */
  //   background-color: #f6f7fb;
  //   width: 100%;
  //   color: $green-color !important;
  //   border-bottom-left-radius: 25px;
  //   border-top-left-radius: 25px;
  //   // margin-right: 30px;
  //   .sidebar_svg_icons {
  //     fill: $green-color;
  //   }
  // }
}
.card {
  background-color: transparent !important;
  border: none !important;
  // &:hover {
  //   background-color: transparent !important;
  // }
}
.card-header {
  padding: 0;
  margin-bottom: 0;
  background-color: transparent !important;
  border-bottom: none !important;
}

.style_nav_itemes {
  color: #fff;
  text-decoration: none !important;
  font-size: 18px;
  font-weight: 700;
  padding-left: 45px;
  ul {
    list-style-type: none !important;
    &:hover {
      background-color: #ffffff2b;
      border: 1px solid #ffffff90;
      border-radius: 20px;
    }
  }
  &:hover {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
  }
}
.fc-v-event .fc-event-main {
  color: unset !important;
}
