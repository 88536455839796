.fc-toolbar-title {
  font-size: 1.5rem !important;
}
@media screen and (max-width: 480px) {
  .fc-toolbar-title {
    // padding-left: 1rem !important;
    font-size: 1rem !important;
    text-align: center;
    // border: 2px solid !important;
  }
}
.fc-daygrid-day-number {
  text-decoration: none !important;
  color: $green-color !important;
}
.fc-col-header-cell-cushion {
  text-decoration: none !important;
  color: $green-color !important;
}
.fc-dayGridMonth-button,
.fc-timeGridDay-button,
.fc-next-button,
.fc-prev-button {
  background-color: #ffffff !important;
  color: $green-color !important;
  border-color: $green-color !important;
  //   box-shadow: 0 0 3px 3px #000000;
}
.fc-dayGridMonth-button:hover,
.fc-timeGridDay-button:hover,
.fc-next-button:hover,
.fc-prev-button:hover {
  background-color: $green-color !important;
  color: #ffffff !important;
}
// .fc-event {
//   display: flex !important;
//   flex-direction: row !important;
//   align-content: flex-start !important;
//   justify-content: flex-start !important;
// }
.fc-event-title {
  white-space: normal !important;
}
.calender_info {
  height: 5px;
}
@media screen and (max-width: 1000px) {
  .fc-event-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px; /* fallback */
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
}
