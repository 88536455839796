.table_column_borders {
  background-color: #f7f5f5;
}
.adver_btn_class {
  background-color: #fff;
  border: none !important;
  color: #6c757d;
  &:hover {
    color: #6c757d;
  }
  &:focus {
    box-shadow: none;
  }
}
.syndication_table_style {
  .ReactTable .rt-tbody .rt-tr-group {
    border-bottom: solid 1px rgb(0 0 0 / 46%) !important;
  }
  .ReactTable .rt-tbody .rt-td {
    border-right: 1px solid rgb(0 0 0 / 11%);
  }
}
// .history_btn-style {
//   border-bottom: 1px solid #000 !important;
//   background-color: transparent;
//   border: none;
//   transition: 0.3s all;
//   &:hover {
//     color: #fff;
//     background-color: #0d6efd;
//     border-radius: 10px;
//   }
// }
