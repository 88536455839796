body {
  margin: 0;
  font-family: open-sans, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f7fb !important;
  word-wrap: break-word;
}
code {
  font-family: open-sans, source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}
::-webkit-scrollbar {
  width: 7px !important;
  height: 7px !important;
  background-color: transparent !important;
  box-shadow: none;
}
::-webkit-scrollbar-track {
  background-color: transparent !important;
}
::-webkit-scrollbar-thumb {
  background-color: $gray-color;
  border-radius: 10px;
}

.disabled_button_pages {
  pointer-events: none;
  opacity: 0.3;
}
.full-main-page {
  padding: 20px;
  /* width: 100%; */
  margin-right: 0 !important;
  margin-left: 85px;
  transition: margin-left 0.5s;
  position: relative;
  top: 60px;
  z-index: 0;
}

.half-main-page {
  padding: 20px;
  margin-right: 0 !important;
  margin-left: 250px;
  transition: margin-left 0.5s;
  position: relative;
  top: 60px;
  z-index: 0;
}
@media screen and (max-width: 575px) {
  .full-main-page {
    padding: 20px;
    margin: 0;
    padding: 0;
    position: relative;
    top: 60px;

    /* width: 100%; */
    /* padding: 0;
      margin: 0; */
    /* width: 100%; */
  }
  .half-main-page {
    padding: 20px;
    margin: 0;
    padding: 0;
    position: fixed;
    top: 60px;
    /* width: 100%; */
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  // font-weight: bold;
  font-family: open-sans, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: $green-color;
}
.inner_header {
  font-family: open-sans, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: $gray-color !important;
}
::-webkit-calendar-picker-indicator {
  background-image: url("../icon/Calendar1.png");
  cursor: pointer;
}
// input[type="time"]::-webkit-calendar-picker-indicator {
//   background-image: url("../icon/time.png") !important;
//   cursor: pointer;
// }
input[type="time"]::-webkit-calendar-picker-indicator {
  background-image: url("../icon/time.png");
}
