.window_sticker_font_family {
  font-family: "Times New Roman", Times, serif;
}
.table_borders {
  border-top: 2px solid #f1f1f1;
}
.special_design_responsive-prive {
  &::after {
    content: "";
    display: inline;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 42%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg);
    background-color: red;
    position: absolute;
    width: 120px;
    @media screen and (max-width: 375px) and (min-width: 300px) {
      left: 30%;
    }
    @media screen and (max-width: 767px) and (min-width: 376px) {
      left: 40%;
    }
  }
}
.special_design-prive {
  &::after {
    content: "";
    display: inline;
    height: 2px;
    width: 100%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg);
    background-color: red;
    position: absolute;
    width: 120px;
    left: 43%;
  }
}
.special_design_first-prive {
  &::after {
    content: "";
    display: inline;
    height: 2px;
    width: 100%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg);
    background-color: red;
    position: absolute;
    width: 80px;
    left: 33%;
  }
}
@media screen and (max-width: 992px) {
  .table_responsive_border {
    border-top: 2px solid #f1f1f1;
  }
}
.table_borders_tds {
  border-top: 2px solid #636363;
}
.table_borders_forth {
  border: 2px solid #636363;
}
.print_window_sticker-container {
}
.border_arounde_window_sticker_section {
  border: 0.5px solid;
  border-radius: 25px;
}
.border_arounde_window_sticker_section_vehicle_image {
  border: 0.5px solid;
  border-radius: 10px;
}
.header_font_size_window_sticker_section {
  font-size: 24px;
  font-weight: bolder;
  font-family: "Times New Roman", Times, serif;
}
.header_font_size_second_window_sticker_section {
  font-size: 2.6rem;
  font-weight: bolder;
  white-space: normal;
  color: $black-color;
  -webkit-text-stroke: 1px $black-color;
  font-family: open-sans-extra-bold !important;
}
.text_space_window_sticker{
  word-spacing: 6.5px !important;
}
.header_font_size_second_window_sticker_section_last {
  font-size: 2.3rem;
  font-weight: bolder;
  white-space: normal;
  color: #000;
  font-family: oswaldBold !important;
}
.header_font_size_third_window_sticker_section {
  font-size: 1.4rem;
  font-weight: bolder;
}
.header_font_size_forth_window_sticker_section {
  font-size: 2rem;
  font-weight: bolder;
}
.sub_header_font_size_window_sticker_section {
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
}
.price_header_font_size_window_sticker_section {
  font-size: 28px;
  font-weight: bolder;
  white-space: nowrap;
}
.digarded_price_window_sticker {
  position: relative;
}
.digarded_price_window_sticker::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #ff002b !important;
  bottom: 50%;
  left: 0;
  transform: rotate(-20deg);
}

.background_window_sticker_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}
.background_window_sticker {
  width: 200px;
  height: 200px;
  object-fit: cover;
}
.second_type_window_sticker_qr_container {
  position: relative;
  z-index: 0;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #dfe7ee;
    z-index: -1;
  }
}
.color_changer-window_sticker {
  color: $black-color;
  font-weight: 600;
}
.header_font_size_second_window_sticker_section {
  width: fit-content;
  font-size: 2rem;
  font-weight: bolder;
  color: $black-color;
  // -webkit-text-stroke: 1px $gray-color;
  font-family: open-sans-extra-bold !important;
}

.window_sticker_text_shadow {
  color: #000;
  font-size: 16px;
  font-weight: bolder;
  // -webkit-text-stroke: 5px black;
}
.select_window_sticker-style {
  transform: scale(1);
  transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
}
.select_window_sticker-style:hover {
  transform: scale(1.1);
  transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
  .box_shadow_window_sticker_select {
    box-shadow: 0 0 15px 1px $green-color !important;
  }
}
.box_shadow_window_sticker {
  box-shadow: 0 0 15px 1px $green-color !important;
}

.seconde_background_info_windowsticker {
  // background-image: url("../../image/a4(2).png");

  // background-color: orangered;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media screen and (max-width: 47.9375rem) {
    background-size: 100%;
  }
  img {
    max-width: 80px;
  }
  h4 {
    font-weight: 700;
  }
}
.table_container_style {
  & img {
    max-height: 220px;
  }
}
@media screen and (min-width: 992px) {
  .table_container_style {
    position: relative;
    & > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.table_container_style_non_responsive {
  position: relative;
  & > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.print_third_window_sticker {
  position: relative;
}
// .window_sticker_bg {
//   background-color: #f1f1f1;
//   background-size: 33%;
//   background-position: right;
// }
.header_title_sizing {
  h2 {
    border-bottom: 1px dashed rgb(166, 166, 166);
    font-size: 40px !important;
    font-weight: 900 !important;
    color: #000;
  }
}
.vehicle_detail_thrd_one_txt {
  color: #88090c;
  font-weight: 700;
  font-size: 22px;
}
.special_design_responsive_third-prive {
  &::after {
    content: "";
    display: inline;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 27%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg);
    background-color: #88090c;
    position: absolute;
    width: 100px;
    @media screen and (max-width: 375px) and (min-width: 300px) {
      left: 30%;
    }
    @media screen and (max-width: 767px) and (min-width: 376px) {
      left: 40%;
    }
    @media screen and (max-width: 1000px) and (min-width: 768px) {
      left: 40%;
    }
  }
}
.special_design_third-prive {
  &::after {
    content: "";
    display: inline;
    height: 2px;
    width: 100%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg);
    background-color: #88090c;
    position: absolute;
    width: 100px;
    left: 26%;
  }
}
.special_design_responsive_forth-prive {
  &::after {
    content: "";
    display: inline;
    height: 2px;
    width: 100%;
    top: 65%;
    left: 36.5%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg);
    background-color: #fff;
    position: absolute;
    width: 60px;
    @media screen and (max-width: 375px) and (min-width: 300px) {
      left: 40%;
    }
    @media screen and (max-width: 767px) and (min-width: 376px) {
      left: 42%;
    }
    @media screen and (max-width: 1000px) and (min-width: 768px) {
      left: 42%;
    }
  }
}
.special_design_forth-prive {
  &::after {
    content: "";
    display: inline;
    height: 2px;
    width: 100%;
    top: 65%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg);
    background-color: #fff;
    position: absolute;
    width: 60px;
    left: 40%;
  }
}
.sticky_window_second {
  font-family: "Times New Roman", Times, serif;
  color: #15588e;
}
// .window_sticker_bg {
//   background: #f1f1f1 33% 100% right;
// }
.print_forth_window_sticker {
  background-color: #f6f6f6;
}
.print_forth_window_sticker_first {
  border: 5px solid #1c1c1c;
}
.forth_desc_color {
  font-size: 1.5rem;
  font-weight: bolder;
  white-space: normal;
  color: #595959 !important;
}
.forth_fontsize_maker {
  font-size: 1.4rem;
  font-weight: 700;
  white-space: normal;
  color: #fff;
  font-family: oswaldBold !important;
}
.text_limit_desc_seconde {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 9; /* number of lines to show */
  line-clamp: 9;
  letter-spacing: 1.5px;
  -webkit-box-orient: vertical;
  line-height: 25px;
  max-height: 225;
  font-size: 15px;
}
.text_limit_desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 9; /* number of lines to show */
  line-clamp: 9;
  letter-spacing: 1.5px;
  -webkit-box-orient: vertical;
  line-height: 21px;
  max-height: 225px;
}
.text_limit_desc_third {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10; /* number of lines to show */
  line-clamp: 10;
  letter-spacing: 1.5px;
  -webkit-box-orient: vertical;
  line-height: 20.5px;
  max-height: 225px;
}
.text_limit_desc_trim {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  letter-spacing: 1.5px;
  -webkit-box-orient: vertical;
  line-height: 20px;
  max-height: 20px;
}
