.Popover_Style {
  @media screen and(max-width: 47.9375rem) {
    position: absolute;
    cursor: pointer;
    width: 165px;
    top: 50px;
    z-index: 1000;
  }

  @media screen and(min-width: 47.93rem) and(max-width: 118.75rem) {
    position: absolute;
    cursor: pointer;
    width: 165px;
    left: 130px;
    top: -20px;
    z-index: 1000;
  }

  @media screen and(min-width: 118.75rem) {
    position: absolute;
    cursor: pointer;
    width: 165px;
    left: 130px;
    top: -20px;
    z-index: 1000;
  }
}
