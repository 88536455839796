.signin-images {
  position: absolute;
  bottom: 5%;
  right: 2%;
}
.signin_page_background {
  min-height: 100vh;
  width: 100%;
  background-image: url("../image/Depositphotos_20067033_xl-2015.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-height: 723px) {
    height: 100%;
  }
  
}
.signin_form_style {
  background-color: rgba($color: #fff, $alpha: 0.5);

  // opacity: 0.5;
  // filter: blur(20px);
}
.social_signin_style {
  position: fixed;
  bottom: 10px;
  background-color: transparent;
  color: #fff;
}
.social_signin_items_style {
  .span_display {
    display: none;
  }
  transform: scale(1.2);
  transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
}
.social_signin_items_style:hover {
  // color: #d15f5c;
  // background-color: red;
  // border-top-right-radius: 20px;
  // border-bottom-right-radius: 20px;
  transform: scale(1.6);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  cursor: pointer;
  .span_display {
    display: flex;
    font-size: 10px;
  }
}
.hillz_image_log_signin {
  width: 300px;
  height: 300px;
}
@media screen and (max-width: 576px) {
  // .social_signin_style {
  //   width: 100%;
  // }
  .hillz_image_log_signin {
    width: 270px;
    height: 270px;
  }
}
@media screen and (max-width: 350px) {
  .social_signin_style {
    width: 100%;
  }
  .hillz_image_log_signin {
    width: 180px;
    height: 180px;
  }
}
