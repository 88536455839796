$bill_of_sale_header-red: #9e0b0f;
$bill_of_sale_header-black: #363636;
.bill_of_sale_content_header-style {
  //   width: 55%;
  position: relative;
  background-color: $bill_of_sale_header-black;
  z-index: 1;
}
.bill_of_sale_content_header-style::after {
  content: " ";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 140px;
  z-index: -1;
  background-color: $bill_of_sale_header-black;

  transform-origin: bottom left;
  -ms-transform: skew(+35deg, 0deg);
  -webkit-transform: skew(+35deg, 0deg);
  transform: skew(+35deg, 0deg);
}
.header_red_div-seprater-style {
  width: 40%;
  height: 20px;
  background-color: $bill_of_sale_header-red;
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 2;
}
.header_red_div-seprater-style-child {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.header_red_div-seprater-style-child::before {
  content: " ";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  right: 80px;
  z-index: -1;
  background-color: $bill_of_sale_header-red;
  transform-origin: bottom left;
  -ms-transform: skew(+35deg, 0deg);
  -webkit-transform: skew(+35deg, 0deg);
  transform: skew(+35deg, 0deg);
}
.header_red_div-seprater-style-child::after {
  content: " ";
  position: absolute;
  top: 20px;
  left: 12px;
  width: 0;
  height: 0;
  border-left: 37px solid transparent;
  border-right: 38px solid transparent;
  border-top: 41px solid #252525;
  z-index: 3;
}
.bill_of_sale-form-header-without-text {
  height: 7px;
  background-color: $bill_of_sale_header-red;
}
.bill_of_sale-form-header-with-text {
  position: relative;
  background-color: $bill_of_sale_header-red;
  z-index: 1;

  // .bill_of_sale-form-header-with-text::after {
  //   content: " ";
  //   position: absolute;
  //   display: block;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 50px;
  //   z-index: -1;
  //   background-color: $bill_of_sale_header-red;
  //   transform-origin: bottom left;
  //   -ms-transform: skew(+35deg, 0deg);
  //   -webkit-transform: skew(+35deg, 0deg);
  //   transform: skew(+35deg, 0deg);
  // }
  .bill_of_sale_header-section {
    white-space: nowrap;
    color: #ffffff;
    font-size: 10px;
  }
}
.bill_of_sale_form-lable-input-style {
  font-size: 10px;
}
.bill_of_sale_form-lable-input-style.inventory {
  line-height: 10px;
}
.bill_of_sale_footer-background {
  background-color: $bill_of_sale_header-black;
}
.bill_of_sale-hillz-info {
  background-color: $bill_of_sale_header-red;
  position: relative;
  z-index: 10;
}
.bill_of_sale-hillz-info::after {
  content: " ";
  position: absolute;
  display: block;
  width: 20%;
  height: 100%;
  top: 0;
  left: 204px;
  z-index: 0;
  background-color: $bill_of_sale_header-red;
  transform-origin: bottom left;
  -ms-transform: skew(+35deg, 0deg);
  -webkit-transform: skew(+35deg, 0deg);
  transform: skew(+35deg, 0deg);
}
.bill_of_sale-hillz-info::before {
  content: " ";
  position: absolute;
  display: block;
  width: 20%;
  height: 100%;
  top: 0;
  right: 204px;
  z-index: 0;
  background-color: $bill_of_sale_header-red;
  transform-origin: bottom left;
  -ms-transform: skew(-35deg, 0deg);
  -webkit-transform: skew(-35deg, 0deg);
  transform: skew(-35deg, 0deg);
}
.bill_of_sale_form-section-gray {
  background-color: rgba($color: #c6c6c6, $alpha: 0.1);
}
.bill_of_sale-description-vehicle-section {
  line-height: 15px !important;
}
.bill_of_sale_border_items_div__container {
  border: 1px solid #c0c0c0 !important;
}

.quotation-link {
  a {
    text-decoration: none !important;
  }
}
