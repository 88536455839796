@font-face {
  font-family: open-sans-semi-bold;
  src: url("../../fonts/Open_Sans/OpenSans-SemiBold.ttf");
}
.navbar-section {
  font-family: open-sans-semi-bold, "Segoe UI", Tahoma, Geneva, Verdana,
    sans-serif;
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  color: $green-color;
  z-index: 2;
}
.menu_icon-container {
  /* margin-left: 20px; */
  display: inline-block;
  cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 3px;
  background-color: $gray-color;
  transition: 0.4s;
  margin: 6px 0;
  border-radius: 10px;
}

.change .bar2 {
  opacity: 0;
}
.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 4px);
  transform: rotate(-45deg) translate(-9px, 4px);
}
.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -4px);
  transform: rotate(45deg) translate(-8px, -4px);
}

.icon-admin-drawer-style {
  color: $gray-color;
  font-size: 24px;
}
.notfication_item {
  a {
    text-decoration: none !important;
  }
  &:hover {
    transition: all 0.5s;
    transform: scale(1.05);
  }
}

.notfications_item_container {
  cursor: pointer;
}
.navbar_icon-1 {
  font-size: 30px;
  color: $gray-color;
}
.navbar_icon-1:hover {
  font-size: 32px;
  color: $green-color;
}
.navbar_icon-3 {
  font-size: 30px;
  color: $gray-color;
}
.navbar_icon-3:hover {
  font-size: 32px;
  color: $green-color;
}
.bell-container:hover {
  /* font-size: 32px; */
  color: $green-color;
}
.navbar_icon-2 {
  font-size: 25px;
  color: $gray-color;
}
.navbar_icon-2:hover {
  font-size: 27px;
  color: $green-color;
}
.dropdown-item_icon {
  font-size: 20px;
  color: $gray-color;
}

.bell-container {
  position: relative;
  padding: 6px;
  cursor: pointer;
  border-radius: 10px;
}
.counter-bell {
  font-family: open-sans-semi-bold, "Segoe UI", Tahoma, Geneva, Verdana,
    sans-serif;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  background-color: $pink-color;
  color: #ffffff;
  /* border: solid 2px $gray-color; */
  border-radius: 50%;
}
.counter-bell-number {
  font-size: 12px;
  text-align: center;
  margin: 0px 3px 0px 0px;
}

.dropdown {
  padding: 0;
  outline: 0 !important;
  border-style: none !important;
  border: 0 !important;

  /* opacity: 0.5; */
}
/* .dropdown:hover {
  opacity: 1;
} */

.dropdown::after {
  display: none !important;
  border: 0 !important;
  outline: 0 !important;
  border-style: none !important;
  opacity: 1;
}
.dropdown:focus {
  outline: 0 !important;
  text-decoration: none;
  border-style: none !important;
  box-shadow: none !important;
  opacity: 1;
}
.dropdown-item-style :hover {
  background-color: $green-color !important;
}
.dropdown-items-menu {
  // color: #000;
  font-size: 0.9rem;
  font-weight: lighter;
}
.dropdown-item-style :hover .dropdown-items-menu {
  color: #ffffff;
}
.dropdown-item-style :hover .dropdown-item_icon {
  color: #ffffff;
}

// .dropdown-menu {
// position: fixed !important;
// border-radius: 10px !important;
// right: 0 !important;
// top: 60px !important;
// }
.user-photo-container {
  position: relative;
}
.user-photo {
  width: 40px;
  height: 40px;
  border: 2px solid $gray-color;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: border-color 1s;
}
.user-photo:hover {
  cursor: pointer;
  border-color: $green-color;
  transition: border-color 1s;
}
.navbar-notfication-rectangle {
  position: absolute;
  top: 65px;
  right: 45px;
  width: 0px;
  height: 0px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #ffffff;
  z-index: 1px;
}
.navbar-notficationbox-container {
  height: 450px;
  width: 250px;
  position: absolute;
  right: 10px;
  top: 80px;
  overflow: auto;
  z-index: 1px;
  border-radius: 10px;
}
.navbar-notficationbox-content {
  position: relative;
  height: 400px;
  justify-content: space-between;
}
