.form_input-style:focus {
  border: 1px solid $green-color !important;
  box-shadow: 0px 0px 0px 4px rgba(5, 113, 108, 0.2) !important;
}
.form_textarea-style {
  border-radius: 10px !important;
  width: 100% !important;
}
.form_textarea-style:focus {
  border: 1px solid $green-color !important;
  box-shadow: 0px 0px 0px 4px rgba(5, 113, 108, 0.2) !important;
}
.form_submit-btn {
  color: #ffffff !important;
  background-color: #1978a599 !important;
  border-radius: 25px !important;
  transition: box-shadow ease 0.3s;
}
.form_submit-btn:hover {
  background-color: $green-color !important;
}
.form_submit-btn:focus {
  background-color: $green-color !important;
  transition: box-shadow ease 0.3s;
  box-shadow: 0px 0px 0px 4px rgba(5, 113, 108, 0.2) !important;
}
.delete-btn {
  color: #ffffff !important;
  background-color: #e8746199 !important;
  border-radius: 25px !important;
  transition: box-shadow ease 0.3s;
}
.delete-btn:hover {
  background-color: #e87461 !important;
}
.delete-btn:focus {
  background-color: #e87461 !important;
  transition: box-shadow ease 0.3s;
  box-shadow: 0px 0px 0px 4px rgba(5, 113, 108, 0.2) !important;
}
.window_sticker-btn {
  color: #ffffff !important;
  background-color: #05716c99 !important;
  border-radius: 25px !important;
  transition: box-shadow ease 0.3s;
}
.window_sticker-btn:hover {
  background-color: $purple-color !important;
}
.window_sticker-btn:focus {
  background-color: $purple-color !important;
  transition: box-shadow ease 0.3s;
  box-shadow: 0px 0px 0px 4px rgba(175, 126, 193, 0.3) !important;
}
.tab_class-name {
  /* border-bottom: 3px solid blue !important; */
  list-style: none;
  cursor: pointer;
}
.tab_selected_class-name {
  border-bottom: 3px solid $green-color !important;
  list-style: none;
  cursor: pointer;
}
.tab_selected_class-name:focus {
  border-bottom: 3px solid $green-color !important;
}
.tab_class-name:hover {
  border-bottom: 3px solid $green-color !important;
}
// ::-webkit-calendar-picker-indicator {
//   color: $purple-color !important;
//   background-color: $purple-color !important;}
.from_date-input {
  border-bottom-left-radius: 14px;
  border-top-left-radius: 14px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  // border-right: 0;
}
.to_date-input {
  border-bottom-right-radius: 14px;
  border-top-right-radius: 14px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-left: 0;
}
.form-check-input:checked {
  background-color: $green-color !important;
}
.form-check-input {
  background-color: $light-blue-color !important;
}

.form-check-input:disabled {
  opacity: 0.3 !important;
}
.list_items_box-style {
  background-color: #f5f3f4;
  border-radius: 10px;
  -webkit-box-shadow: inset -1px -1px 20px -6px #f5f3f4;
  -moz-box-shadow: inset -1px -1px 20px -6px #f5f3f4;
  box-shadow: inset -1px -1px 20px -6px #f5f3f4;
  height: 100px;
  overflow: hidden;
  overflow-y: scroll;
}
.customer_profiles_active_btn {
  background-color: $green-color !important;
  transition: box-shadow ease 0.3s;
  box-shadow: 0px 0px 0px 4px rgba(5, 113, 108, 0.2) !important;
}

.comment_display {
  border: 1px solid #1978a599;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
}
.comment_display_seller {
  border: 1px solid #e87461;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
}
.comment_display_header {
  font-weight: bold;
  font-size: medium;
  border-bottom: #1978a599;
}
.comment_display_time {
  font-size: small;
  text-align: end;
}
.comment_display_container {
  max-height: 45vh;
  overflow: scroll;
}
.cursor-pointer {
  cursor: pointer;
}
