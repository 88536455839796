.lead-center-reading-section {
  position: fixed;
  top: 63px;
  right: 0;
  height: 100%;
  transform: translateX(600px);
  z-index: 10;
  display: none;
  transition: ease-in 0.5s;
}
.lead-center-reading-section-vis {
  position: fixed;
  top: 63px;
  right: 0;
  height: 100%;
  z-index: 10;
  display: block;

  transition: ease-in 0.5s;
}

.showAdvirtise-reading-section-vis {
  position: fixed;
  top: 10%;
  right: 5%;
  height: 85%;
  z-index: 10;
  border-radius: 16px;
  overflow: scroll;
  display: block;

  transition: ease-in 0.5s;
}
.showAdvirtise-reading-section-button {
  position: fixed;
  top: 25%;
  right: 5%;
  // height: 85%;
  transition-duration: 0.5s;
  z-index: 10;
  border-radius: 16px;
  overflow: scroll;
  max-width: 215px;
}
