.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 20px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #17c6e9;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.table_headerStyle {
  background-color: #1c1a19;
}

.input_style {
  background: transparent;
  border: none !important;
}

.input_style:focus {
  border: 1px solid grey !important;
  outline: #555;
  border-radius: 2px;
  margin-left: 2px;
  margin-right: 2px;
}

.scrollable-container {
  overflow-x: auto;
  /* Enables horizontal scrolling */
  white-space: nowrap;
  /* Prevents table rows from wrapping */
  max-width: 100%;
  /* Ensures the container doesn’t exceed the screen width */
}

.scrollable-container table {
  border-collapse: collapse;
  /* Ensure table layout doesn't break */
}

.scrollable-container::-webkit-scrollbar {
  height: 6px;
  /* Horizontal scrollbar height */
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  /* Scrollbar thumb color */
  border-radius: 4px;
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background-color: #888;
  /* Hover state color */
}