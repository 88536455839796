.jodit_editor-site-setting-container {
  .jodit-container {
    min-height: 300px !important;
    .jodit-workplace {
      min-height: 300px !important;
    }
  }
}
.employee_card-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 300px;
  border: 1px dashed $gray-color;
}
.employee_card_select-container {
  width: 100%;
  height: 100%;
  min-height: 300px;
  border: 1px dashed $gray-color;
  font-size: 2rem;
  color: $gray-color;
  transition: all ease 0.5s;
  cursor: pointer;
  &:hover {
    background-color: rgba($color: $gray-color, $alpha: 0.1);
    transition: all ease 0.5s;
    color: #57cc99;
  }
}
.employee_dns {
  width: 80px;
  height: 80px;
  border: 1px dashed $gray-color;
  font-size: 2rem;
  color: $gray-color;
  transition: all ease 0.5s;
  cursor: pointer;
  &:hover {
    background-color: rgba($color: $gray-color, $alpha: 0.1);
    transition: all ease 0.5s;
    color: #57cc99;
  }
}
.employee_card_select-container-disble {
  width: 100%;
  height: 100%;
  min-height: 300px;
  border: 1px dashed $gray-color;
  font-size: 2rem;
  color: $gray-color;
  transition: all ease 0.5s;
  cursor: default;
}
.employee_image_url-container {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 1px solid $gray-color;
  object-fit: cover;
  object-position: center;
}
.employee_input-visit-style {
  border: none;
  border-bottom: 1px solid;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  &:focus {
    outline: none;
    background-color: rgba($color: $gray-color, $alpha: 0.2);
  }
}
.employee_input-visit-style-readonly {
  border: none;
  border-bottom: 1px solid;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  &:focus {
    outline: none;
  }
}
.style-edit-inventory {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background-color: rgba($color: $gray-color, $alpha: 0.1);
    color: #000;
  }
}
.style-edit-inventory2 {
  color: #fff;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: rgba($color: $light-gray-color, $alpha: 0.1);
    color: #fff;
  }
}
.youtube_link {
  &::placeholder {
    color: #000 !important;
    font-size: 11px;
  }
}
.filterCustomerName {
  color: red;
  // background-color: orange;
}
.search_back {
  color: #000;
  text-decoration: none;
  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  &:nth-child(odd) {
    background-color: rgb(233 233 233 / 57%);
  }
  &:nth-child(even) {
    background-color: #fff !important;
  }
}

.cityInput {
  &::placeholder {
    color: #000 !important;
    font-size: 13px;
  }
}
.result-input-simple-search {
  position: absolute;
  max-height: 250px;
  width: 234px;
  overflow: hidden;
  overflow-y: scroll;
  z-index: 100;
  border: 1px solid $light-blue-color;
  color: #000;
}
.myClanderStyle {
  background-color: #ee4b2b;
  color: #fff;
  border: #ee4b2b;
  &:hover {
    background-color: #ee4b2b !important;
    color: #fff;
    border: #ee4b2b;
  }
}

.fc-daygrid-dot-event .fc-event-title {
  white-space: pre-line !important;
}

.popover {
  border: none !important;
  border-radius: 10px !important;
}
