.img-file-input {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
}
.img-file-lable {
  position: relative;
  z-index: 1;
  top: 0;
}
.img-priv-section {
  min-height: "200px" !important;
  min-width: "200px" !important;
}
.opacity-promotion {
  opacity: 0.5;
}
// @media screen and (max-width: 578px) {
//   .img-priv-section {
//     height: "50px" !important;
//     width: "50px" !important;
//   }
// }
.age_inv-container {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.vehicle_image_overlay-container {
  position: relative;
  width: 50%;
}

.vehicle_image_overlay_temp {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 200px;
  // max-width: 200px;
  width: 75%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba($color: $green-color, $alpha: 0.5);
}
.vehicle_image_overlay-container:hover .vehicle_image_overlay_temp {
  opacity: 1;
}
.vehicle_image_overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 200px;
  max-width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba($color: $green-color, $alpha: 0.5);
}
.vehicle_image_overlay-container:hover .vehicle_image_overlay {
  opacity: 1;
}
.vehicle_image_overlay-text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.active_vehicle_option_accordian-open {
  transform: rotate(180deg);
  transition: transform 0.5s ease;
}
.vehicle_check-error {
  // color: #fcfcfc;
  background-color: #f5cb5c;
  border-radius: 5px;
  // border:  1px $gray-color;
}
.vehicle_check-success {
  // color: #fcfcfc;
  background-color: #6eeb83;
  border-radius: 5px;
  // border:  1px $gray-color;
}
.vehicle_service_detail_devider {
  border: 8px solid #023e8a;
}
.vehicle_service_detail_box {
  border: 2px solid #023e8a;
  min-height: 100px;
}
@media print {
  .info_box_style_for_print_col_7 {
    flex-basis: 60% !important;
  }
  .info_box_style_for_print_col_5 {
    flex-basis: 40% !important;
  }
  .fillable_fields_style {
    font-size: 0.8rem;
  }
  .flex_dir_style {
    flex-direction: row !important;
  }
  .table {
    font-size: 0.8rem !important;
  }
  .service_table_container {
    display: flex !important;
    flex-direction: row !important;
  }
  .service_table_cols {
    float: none !important;
    }
  // .pagebreak {
  //   page-break-before: always !important;
  //   page-break-inside: avoid;
  // }
  // .header_repeated {
  //   display: table-header-group !important;
  // }
  // .body_repeated {
  //   display: table-row-group !important;
  // }
  .footer_repeated {
    display: flex !important;
  }
  .service_table_sections {
    display: flex !important;
    flex-direction: column !important;
    width: 33% !important;
  }
  .display_none_print {
    display: none;
  }
  .flex_fo_print {
    display: flex !important;
    flex-direction: row !important;
  }
}
.fillable_fields_style {
  border-bottom: 1px dashed $gray-color;
  text-align: center;
  // font-size: 0.8rem;
}
.vehicle_video-style:-webkit-full-screen {
  width: 100% !important;
  height: 100% !important;
  max-width: 100vh !important;
  max-height: 100vh !important;
}
.edit_vehicle_images_gallary_component {
  .image-gallery-thumbnail-inner {
    max-width: 100px;
    max-height: 100px;
    & > img {
      max-width: 100px;
      max-height: 100px;
      height: 100%;
      object-fit: contain;
    }
  }
}
.style-sold-as {
  &:nth-child(even) {
    background-color: rgb(151, 151, 151) !important;
    border-radius: 5px !important;
  }
  &:nth-child(odd) {
    background-color: rgb(202, 202, 202) !important;
    border-radius: 5px !important;
  }
}
.place_color {
  background-color: rgba(5, 113, 108, 0.6) !important;
  border: none !important;
  color: #fff;
  &::placeholder {
    color: #fff;
  }
}
// .overflow_alert_scroll {
.react-confirm-alert {
  overflow-x: scroll !important;
}
// }

.customers-wrapper {
  & div:nth-child(odd) {
    background-color: rgb(165, 165, 165);
  }
  & div:nth-child(odd) {
    background-color: rgba(75, 75, 75, 0.124);
    color: #000;
  }
}

.single-customer-wrapper {
  padding-top: 2px;
  padding-bottom: 2px;
}

.my-fixed-height {
  height: 120px;
}

.my-title-style {
  // font-weight: 600;
}
.vehicle_single_detail_div__container {
  span {
    @media screen and (max-width: 47.9375rem) {
      font-size: 16px;
    }

    @media screen and (min-width: 62rem) and (max-width: 118.75rem) {
      font-size: 12.5px;
    }

    @media screen and (min-width: 118.75rem) {
      font-size: 16px;
    }
  }
}
.option_item_style {
  font-size: 15px;
}
.title_detaile_list {
  @media screen and (max-width: 47.9375rem) {
    font-size: 16px;
  }

  @media screen and (min-width: 62rem) and (max-width: 118.75rem) {
    font-size: 12.5px;
  }

  @media screen and (min-width: 118.75rem) {
    font-size: 16px;
  }
}
// .inventory_table {
//   .ReactTable .rt-thead.-header {
//     position: sticky;
//     top: 0;
//     z-index: 2;
//     background: white;
//   }
//   .ReactTable .rt-thead.-filters {
//     position: sticky;
//     top: 41px;
//     z-index: 2;
//     background-color: white !important;
//   }
//   // .ReactTable .rt-tbody {
//   //   overflow: visible !important;
//   //   min-height: 400px;
//   // }
//   .ReactTable .rt-tbody {
//     overflow: visible !important;
//     // max-height: 400px; /* Set a maximum height for the body */
//   }
//   .table_sticky {
//     position: sticky !important;
//     left: 0;
//     top: 0;
//     z-index: 1;
//   }
// }
// .dealInventory_table {
//   .ReactTable .rt-thead.-header {
//     position: sticky;
//     top: 0;
//     z-index: 2;
//     background: white;
//   }
//   .ReactTable .rt-thead.-filters {
//     position: sticky;
//     top: 41px;
//     z-index: 2;
//     background-color: white !important;
//   }
//   .ReactTable .rt-tbody {
//     overflow: visible !important;
//     height: 400px;
//   }
//   .table_sticky {
//     position: sticky !important;
//     left: 0;
//     top: 0;
//     z-index: 1;
//   }
//   .ReactTable .rt-tfoot {
//     position: sticky;
//     z-index: 2;
//     top: 510px;
//     background-color: #fff;
//   }
// }
