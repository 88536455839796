.custome-card-header {
  border-left-width: 3px !important;
  border-right-width: 0px !important;
  border-top-width: 0px !important;
  border-bottom-width: 0px !important;
  border-left-style: solid !important;
  border-left-color: #0d6efd !important;
  border-radius: 2px !important;
  // box-shadow: 2px 2px 2px #0d6efd !important;
}

.custom-input-file {
  display: none;
}

.onoffswitch {
  position: relative;
  width: 110px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

}

.onoffswitch-checkbox {
  position: absolute;
  opacity: 0;
  pointer-events: none;

}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  // border: 2px solid #999999;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
  border-radius: 20px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 14px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  box-sizing: border-box;

}

.onoffswitch-inner:before {
  content: "Active";
  padding-left: 20px;
  background-color: #1978a5;
  color: #FFFFFF;
}

.onoffswitch-inner:after {
  content: "Deactive";
  padding-right: 12px;
  background-color: #888888;
  color: #fff;
  text-align: right;
}

.onoffswitch-switch {
  display: block;
  width: 20px;
  margin: 5px;
  background: #FFFFFF;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 75px;
  // border: 2px solid #999999;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
  right: 0px;
}