@font-face {
  font-family: open-sans-light;
  src: url("../../fonts/Open_Sans/OpenSans-Light.ttf");
}
@font-face {
  font-family: open-sans;
  src: url("../../fonts/Open_Sans/OpenSans-Regular.ttf");
}
@font-face {
  font-family: open-sans-bold;
  src: url("../../fonts/Open_Sans/OpenSans-Bold.ttf");
}
@font-face {
  font-family: open-sans-extra-bold;
  src: url("../../fonts/Open_Sans/OpenSans-ExtraBold.ttf");
}
@font-face {
  font-family: montserrat;
  src: url("../../fonts/Montserrat-Regular.ttf");
}
@font-face {
  font-family: montserratLight;
  src: url("../../fonts/Montserrat-Light.ttf");
}
@font-face {
  font-family: oswaldBold;
  src: url("../../fonts/Oswald-Bold.ttf");
}
@font-face {
  font-family: oswald;
  src: url("../../fonts/Oswald-Regular.ttf");
}
