.text-limit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px; /* fallback */
  max-height: 32px; /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.first-window-sticker-text-limit {
  & > * {
    padding: 0 !important;
    margin: 0 !important;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 20px; /* fallback */
  max-height: 60px !important; /* fallback */
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.seconde-window-sticker-text-limit {
  & > * {
    padding: 0 !important;
    margin: 0 !important;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 20px; /* fallback */
  max-height: 280px; /* fallback */
  -webkit-line-clamp: 8; /* number of lines to show */
  -webkit-box-orient: vertical;
}
