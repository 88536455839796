.chat-input {
  &:focus {
    outline: none !important;
    border: none !important;
    outline-width: 0 !important;
  }
  outline: none !important;
  border: none !important;
  outline-width: 0 !important;
}
.self-chat-box-container {
  border-width: 1px;
  border-radius: 15px;
  border-bottom-left-radius: 0px;
  background-color: $dark-purple-color;
  min-height: 100px;
}
.friend-chat-box-container {
  border-width: 1px;
  border-radius: 15px;
  border-bottom-right-radius: 0px;
  background-color: $light-gray-color;
  min-height: 100px;
}
.chat-image-show-container {
  position: relative;
  width: 120px;
  height: 80px;
  .chat-image-remove {
    position: absolute;
    left: 110px;
    width: 25px;
    height: 25px;
    border-radius: 25px;
  }
}
.chat-seen-icon-container {
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.self-comment-box-container {
  color: $light-gray-color;
}
.friend-chat-box-container {
  color: $dark-purple-color;
}
.chat_container_style {
  overflow: hidden;
  overflow-y: scroll;
  max-height: 550px;
}
.chat_check_color {
  color: $dark-purple-color;
}
