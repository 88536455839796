.user_border-style {
  border-bottom: 2px solid #8e9eab;
  transition: border-radius 1s;
}

.user_border-style:hover {
  cursor: pointer;
  border-radius: 25px;
  transition: border-radius 1s;
  -webkit-box-shadow: -2px 3px 18px 4px rgba(0, 0, 0, 0.16) !important;
  box-shadow: -2px 3px 18px 4px rgba(0, 0, 0, 0.16) !important;
}

.vehicle_border-style {
  overflow: hidden;
  // border-bottom: 1px solid #8e9eab;
  // border-left: 8px solid #8e9eab;
  // border-radius: 3px;
  transition: border-radius 1s;
  // transition: border-left 0.3s;
}

@media print {
  .pagebreak {
    clear: both;
    display: block;
    position: relative;
    page-break-before: always !important;
  }

  .print_comment {
    display: none !important;
  }

  .print_style_top {
    transform: translateY(-35%);
    max-height: fit-content !important;
  }
}

// .print-page {
//   page-break-after: always;
// }
.print_inventory {
  .print-body {
    page-break-after: always;
    // margin-top: 50px; /* Adjust the margin to accommodate the header's height */
  }

  @media print {
    .print-page {
      margin-top: 0px;
      /* Adjust the margin to accommodate the header's height */
      height: 90px;
    }

    @page {
      size: landscape;
      /* Default to portrait orientation */
    }

    @media (orientation: landscape) {
      .print-page p {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        height: 50px;
        background-color: #fff;
        border-bottom: 2px solid #000;
        text-align: center;
      }
    }

    @media (orientation: portrait) {
      .print-page p {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        height: 50px;
        background-color: #fff;
        border-bottom: 2px solid #000;
        text-align: center;
      }
    }

    .print-page p {
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      height: 50px;
      background-color: #fff;
      border-bottom: 2px solid #000;
      text-align: center;
    }

    .print-content {
      margin-top: 90px;
      /* Adjust the margin to accommodate the header's height */
    }
  }
}

.print_Income_Cost {
  .print-body {
    page-break-after: always;
    // margin-top: 50px; /* Adjust the margin to accommodate the header's height */
  }

  @media print {
    .print-page {
      margin-top: 0px;
      /* Adjust the margin to accommodate the header's height */
      height: 50px;
    }

    @page {
      size: landscape;
      /* Default to portrait orientation */
    }

    @media (orientation: landscape) {
      .print-page p {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        height: 50px;
        background-color: #fff;
        border-bottom: 2px solid #000;
        text-align: center;
      }
    }

    @media (orientation: portrait) {
      .print-page p {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        height: 50px;
        background-color: #fff;
        border-bottom: 2px solid #000;
        text-align: center;
      }
    }

    .print-page p {
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      height: 50px;
      background-color: #fff;
      border-bottom: 2px solid #000;
      text-align: center;
    }

    .print-content {
      margin-top: 90px;
      /* Adjust the margin to accommodate the header's height */
    }
  }
}

.vehicle_border-style:hover {
  cursor: pointer;
  border-radius: 25px;
  // border-left: 0;
  border: 1px solid $green-color;
  transition: border-radius 1s;
  // transition: border-left 1s;
  -webkit-box-shadow: -2px 3px 18px 4px rgba(5, 113, 108, 0.2) !important;
  box-shadow: -2px 3px 18px 4px rgba(5, 113, 108, 0.2) !important;
}

.edit_icon-style {
  font-size: 1.2rem;
  color: $pink-color;
  opacity: 0.8;
  transition: opacity 0.3s;
}

.edit_icon-style:hover {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
}

.vehicle_icon-style {
  font-size: 1.4rem;
  color: $green-color;
  opacity: 0.8;
  transition: opacity 0.3s;
}

.vehicle_icon-style:hover {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
}

.trash_icon-style {
  font-size: 1.2rem;
  color: $gray-color;
  opacity: 0.8;
  transition: opacity 0.3s;
}

.primary-label {
  font-size: 1.2rem;
  color: $green-color;
}

.add_icon-style {
  font-size: 1.2rem;
  color: $green-color;
  opacity: 0.8;
  transition: opacity 0.3s;
  cursor: pointer;
}

.absolute-add-icon {
  right: 20px;
  bottom: 0px;
}

.trash_icon-style:hover {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
}

.trash_icon-style-dis {
  font-size: 1.2rem;
  color: rgba($color: $gray-color, $alpha: 0.8);
  opacity: 0.5;
  // transition: opacity 0.3s;
}

.primary-color {
  color: $green-color !important;
}

.table_page_botton {
  font-size: 1.2rem;
  color: $green-color !important;
  border-radius: 25px !important;
}

.table_page_botton:hover {
  color: #ffffff !important;
  background-color: $green-color !important;
  // border-radius: 25px;
}

.sidebar_svg_icons {
  font-size: 1.7rem;
  // fill: $green-color;
  /* color: $gray-color; */
  // margin-right: 30px;
}

.table_page_botton_svg {
  svg {
    // font-size: 1.2rem;
  }

  .table_page_button_svg_sidebar_svg_icons {
    fill: $green-color !important;
  }

  color: $green-color !important;
  fill: $green-color !important;

  border-radius: 25px !important;
}

.table_page_botton_svg:hover {
  svg {
    color: #ffffff !important;
    // border-radius: 25px;
  }

  .table_page_button_svg_sidebar_svg_icons {
    fill: #fff !important;
  }

  background-color: $green-color !important;

  .icon_design_hidden {
    background-image: url("../image/no-hidde\ dollor-withe2.png");
    background-repeat: no-repeat;
    background-size: contain, cover;
    background-position: center;
  }

  .icon_design_show {
    background-image: url("../image/png-dollor-withe1.png");
    background-repeat: no-repeat;
    background-size: contain, cover;
    background-position: center;
  }
}

.icon_design_hidden {
  background-image: url("../image/no-hidde\ dollor-blue2.png");
  background-repeat: no-repeat;
  background-size: contain, cover;
  background-position: center;
  height: 30px;

  &:hover {
    background-image: url("../image/no-hidde\ dollor-withe2.png");
    background-repeat: no-repeat;
    background-size: contain, cover;
    background-position: center;
  }
}

.icon_design_show {
  background-image: url("../image/png-dollor-blue1.png");
  background-repeat: no-repeat;
  background-size: contain, cover;
  background-position: center;
  height: 30px;

  &:hover {
    background-image: url("../image/png-dollor-withe1.png");
    background-repeat: no-repeat;
    background-size: contain, cover;
    background-position: center;
  }
}

.table_page_botton_2 {
  font-size: 1.2rem;
  color: $purple-color !important;
  border-radius: 25px;
}

.table_page_botton_2:hover {
  color: #ffffff !important;
  background-color: $purple-color !important;
  border-radius: 25px;
}

.latest_tasks-container {
  max-height: 250px;
  overflow: hidden;
  overflow-y: scroll;
}

.latest_follow_ups-container {
  max-height: 350px;
  overflow: hidden;
  overflow-y: scroll;
}

.latest_costs-container {
  max-height: 550px;
  overflow: hidden;
  overflow-y: scroll;
}

.latest_leads-container {
  max-height: 200px;
  overflow: hidden;
  overflow-y: scroll;
}

.selected_border-table {
  overflow: hidden;
  background-color: rgba($color: $green-color, $alpha: 0.05);
  border: 1px solid $green-color;
  cursor: pointer;
  border-radius: 25px;
}

.hovered_border-table:hover {
  overflow: hidden;
  background-color: rgba($color: $green-color, $alpha: 0.05);
  border: 1px solid $green-color;
  cursor: pointer;
  border-radius: 25px;
}

.rounded_input-style {
  input,
  select {
    border-radius: 10px !important;
    width: 100% !important;
  }

  input:focus,
  select:focus {
    border: 1px solid $green-color !important;
    width: 100% !important;
    // box-shadow: 0px 0px 0px 4px rgba(5, 113, 108, 0.2) !important;
  }
}

.table_header_style {
  background-color: $green-color !important;
  color: #ffffff !important;
}

.-sort-desc,
.-sort-asc {
  background-color: #ffffff;
  color: #000000;
  // border-radius: 25px;
}

// .-pagination {
//   position: fixed !important;
//   width: 100% !important;
//   background-color: #ffffff;
//   bottom: 0;
// }
// .ReactTable .rt-thead {
//   overflow-y: scroll !important;
// }
.left_border-table-style {
  border-left: 2px solid $green-color !important;
}

.table_border_column_devider-style > * {
  border-right: 1px solid rgba($color: #2b2b2b, $alpha: 0.3) !important;
}

.trash_icon_for_image-background {
  background-color: rgba($color: #fff, $alpha: 1);
  font-size: 1rem;
  width: 35px;
  height: 35px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 100%;
}

.print_inventory_Vehicle {
  .print-body {
    page-break-after: always;
    // margin-top: 50px; /* Adjust the margin to accommodate the header's height */
  }

  @media print {
    .print-Firstpage {
      margin-top: 0px;
      /* Adjust the margin to accommodate the header's height */
      height: 90px !important;
    }

    .print-page {
      margin-top: 0px;
      /* Adjust the margin to accommodate the header's height */
      height: 65px;
    }

    @page {
      size: landscape;
      /* Default to portrait orientation */
    }

    .pagebreak {
      clear: both;
      display: block;
      position: relative;
      page-break-before: always !important;
    }

    @media (orientation: landscape) {
      .print-page p {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        height: 50px;
        background-color: #fff;
        border-bottom: 2px solid #000;
        text-align: center;
      }
    }

    @media (orientation: portrait) {
      .print-page p {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        height: 50px;
        background-color: #fff;
        border-bottom: 2px solid #000;
        text-align: center;
      }
    }

    .print-page p {
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      height: 50px;
      background-color: #fff;
      border-bottom: 2px solid #000;
      text-align: center;
    }

    .print-content {
      margin-top: 0px;
      /* Adjust the margin to accommodate the header's height */
    }
  }
}
